/*
|--------------------------------------------------------------------------
| Alerts utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components to manage the alerts in the forms
| of the application.
*/

// import Vue.js features
import { reactive } from 'vue'

// import external functionalities
//import { Modal } from 'bootstrap';

import { useI18n } from 'vue-i18n'


export default function useModalsComp() {

    const { t } = useI18n({ useScope: 'global' })

    const state = reactive({

        modal: {
            header: '',
            action: '',
            componentEdit: null,
            componentShow: null,
            componentAlert: null,
            componentGroupChangeAlert: null,
        },
    })


    function displayAlertComponent() {

        // Define modal attributes
        state.modal.header = ''
        state.modal.action = ''

        // Open Modal component
        state.modal.componentAlert.toggle()
        // Reset message after a few milliseconds
        setTimeout(() => {
            state.modal.componentAlert.toggle()
        }, 1000)
    }

    //function setCreateModalAttributes(header, action) {
    /**
     * Set resource attributes to display in the modal header and
     * the action to proceed on the resource.
     * The Modal toggle is set too.
     *
     * @param {String} resource  Resource name
     */
    function setCreateModalAttributes(resource) {

        // Define modal attributes
        state.modal.header = t(`form.cards.${resource}.headers.create`)
        state.modal.action = 'create'

        // Open Modal component
        state.modal.componentEdit.toggle()
    }

    //function setCreateModalAttributes(header, action) {
    function setEditModalAttributes(resource) {

        // Define modal attributes
        state.modal.header = t(`form.cards.${resource}.headers.edit`)
        state.modal.action = 'edit'

        // Open Modal component
        state.modal.componentEdit.toggle()
    }

    //function setCreateModalAttributes(header, action) {
    function setShowModalAttributes(resource) {

        // Define modal attributes
        state.modal.header = t(`form.cards.${resource}.headers.show`)
        state.modal.action = 'show'

        // Open Modal component
        state.modal.componentShow.toggle()
    }

    //function setCreateModalAttributes(header, action) {
    function setGroupChangeAlertModalAttributes(resource) {

        // Define modal attributes
        state.modal.header = t(`form.cards.${resource}.headers.edit`)
        state.modal.action = 'edit'

        // Open Modal component
        state.modal.componentGroupChangeAlert.toggle()
    }

    //function setCreateModalAttributes(header, action) {
    function setDeleteModalAttributes(resource) {

        // Define modal attributes
        state.modal.header = t(`form.cards.${resource}.headers.delete`)
        state.modal.action = 'delete'

        // Open Modal component
        state.modal.componentGroupChangeAlert.toggle()
    }


    /**
     * Returned data to the calling component
     */
    return {
        state,
        displayAlertComponent,
        setCreateModalAttributes,
        setEditModalAttributes,
        setShowModalAttributes,
        setGroupChangeAlertModalAttributes,
        setDeleteModalAttributes,
    }
}
